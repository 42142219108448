import dashboard from "./menu/dashboard";
import senior from "./menu/senior";
import master from "./menu/master";
import agent from "./menu/agent";
import player from "./menu/player";
import transaction from "./menu/transaction";
import balanceReport from "./menu/balanceReport";
import cockBalanceReport from "./menu/cockBalanceReport";
import baccaratBalanceReport from "./menu/baccaratBalanceReport";
import requestDeposit from "./menu/requestDeposit";
import requestWithdraw from "./menu/requestWithdraw";
import assistant from "./menu/assistant";

export default [
  ...dashboard,
  ...senior,
  ...master,
  ...agent,
  ...player,
  ...assistant,
  ...transaction,
  ...balanceReport,
  ...cockBalanceReport,
  ...baccaratBalanceReport,
  ...requestDeposit,
  ...requestWithdraw,
]
